#topNavbar .nav-link.active {
  border-bottom: 2px solid var(--primary);
}

.navbar-light .navbar-toggler {
  color: #2f2d97;
  border-color: rgba(0, 0, 0, 0);
}

.mini-profile-info {
  background-color: #efefefb4;
}

.mini-avatar-container {
  background-color: #fff;
  position: relative;
  height: 4.5rem;
  width: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 14px 55px rgba(0, 0, 0, 0.11);
  margin-right: auto !important;
  margin-left: auto !important;
}

.mini-avatar-text {
  font-weight: bold;
  font-size: 2rem;
  color: var(--primary);
}

.mini-avatar-badge {
  width: 1.2rem;
  width: 1.2rem;
  position: absolute;
  bottom: 0;
  right: 0.5rem;
}

.bg-white {
  background-color: #fff !important;
}

.mini-info-card {
  max-width: 15rem;
}

@media (min-width: 768px) {
  .main-nav {
    display: flex;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
}
