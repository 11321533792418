.avatar-container{
    position: relative;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 14px 55px rgba(0, 0, 0, 0.11);
    margin-right: auto!important;    
    margin-left: auto!important;
}

.avatar-text{
    font-weight: bold;
    font-size: 40px;
    color: var(--primary);
}

.avatar-badge{
    width: 32px;
    width: 32px;
    position: absolute;
    bottom: 0;
    right: 1.5rem;
}