.loyalty-card {
  background-color: #f5f5fb !important;
  border: 1px solid var(--primary);
  border-radius: 1rem;
  max-width: 15rem;
}

.card-number-text {
  font-size: 1rem;
}

.title-text {
  font-size: 0.625rem;
}
