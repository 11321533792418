html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-bottom: 0 !important;
}

.card {
  box-shadow: 0px 14px 55px rgba(0, 0, 0, 0.11);
  border: 0 !important;
  border-radius: 0 !important;
}

hr {
  border-style: dashed !important;
  border-color: var(--seondary);
}

.border-dashed {
  border-width: 1px;
  border-style: dashed !important;
  border-color: #0000001a;
}

.line-height-p5 {
  line-height: 0.5;
}

.line-height-p8 {
  line-height: 0.8;
}

.line-height-2 {
  line-height: 2;
}

.text-smaller {
  font-size: 70% !important;
}

.text-extra-smaller {
  font-size: 10px;
}

.btn {
  border: 0px solid transparent;
  border-radius: 0 !important;
}

.shadow {
  box-shadow: 0px 14px 55px rgba(0, 0, 0, 0.11);
}

.top-left-0 {
  top: 0;
  left: 0;
}

.bg-muted {
  background-color: #efefef;
}

.form-control {
  border: 1px dashed #ced4da !important;
  border-radius: 0rem !important;
}

.form-control:focus {
  color: #2c3090 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.modal-content {
  border: 0 !important;
  border-radius: 0 !important;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-footer {
  border-top: 0 !important;
}

.remove-spinner::-webkit-inner-spin-button,
.remove-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.carousel .control-dots {
  position: initial !important;
  padding-inline-start: 0px;
  margin: 0 !important;
}

.carousel .control-dots .dot {
  background: var(--primary) !important;
}

.carousel.carousel-slider .control-arrow {
  background: rgb(255, 255, 255, 1) !important;
}

.button-icon-width {
  width: 2rem;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.clickable {
  cursor: pointer;
}

.shadow-vc {
  box-shadow: 0px -6px 20px rgb(0 0 0 / 11%);
}

.badge-REDEMPTION {
  color: #fff;
  background-color: #fd7e14;
}

.badge-COLLECTION {
  color: #fff;
  background-color: #1db617;
}

.badge-ADJUSTMENT {
  color: #fff;
  background-color: #8a31ff;
}

.details-view {
  background-color: #efefef;
}

.onload-opacity {
  animation-name: onload;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

.text-orange {
  color: var(--orange);
}

.word-break {
  word-break: normal;
}
